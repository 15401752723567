import React from 'react'

const Header = () => {
  return (
    <header>
      <img src='/images/logo-adhoc-negro-azul.svg' alt='Logo'/>
    </header>
  )
}

export default Header
